<template>
    <v-container v-if="newsItem">
        <div class="d-md-flex mb-8 line-bottom flex-wrap pb-4">
            <h1 class="text-h1 mb-3 mb-md-0">{{ newsItem.title }}</h1>
            <v-breadcrumbs :items="breadcrumbs" class="ml-auto pa-0"></v-breadcrumbs>
            <div class="break"></div>
            <span class="text--disabled">{{ newsItem.date|luxon('dd-MM-yyyy') }}</span>
        </div>

        <v-row class="mb-5">
            <v-col md="3" class="order-md-2" v-if="newsItem.image">
                <v-img
                    :src="newsItem.image.url"
                    max-width="100%"
                    :alt="newsItem.title"
                    class="d-sm-none d-md-flex"
                ></v-img>
                <v-img
                    :src="newsItem.image.url"
                    max-height="150px"
                    :alt="newsItem.title"
                    class="d-none d-sm-flex d-md-none"
                ></v-img>
            </v-col>
            <v-col md="9" class="order-md-1">
                <div v-html="newsItem.content" class="news-content cms-content"></div>
            </v-col>
        </v-row>

        <v-btn plain :to="{name: 'NewsOverview'}" color="primary" class="ml-n3 mb-4">
            <v-icon small class="mr-2">fal fa-long-arrow-left</v-icon>
            Back to news overview
        </v-btn>
    </v-container>
</template>

<script>
export default {
    name: "NewsItem",
    mounted () {
        this.loadNewsItem();
        this.$store.dispatch('setPageTitle', 'News')
    },
    data: () => ({
        newsItem: null,
        breadcrumbs: [
            {
                text: 'Home',
                disabled: false,
                to: { name: 'Home' },
                exact: true
            },
            {
                text: 'News',
                disabled: false,
                to: { name: 'NewsOverview' },
                exact: true
            }
        ]
    }),
    watch: {
        '$route.params.slug' () {
            this.loadNewsItem();
        }
    },
    methods: {
        loadNewsItem() {
            let slug = this.$route.params.slug;
            this.$store.dispatch('getNewsItem', { slug: slug }).then(newsItem => {
                this.newsItem = newsItem;
                this.$store.dispatch('setPageTitle', 'News - ' + this.newsItem.title)

                this.breadcrumbs.push({
                    text: newsItem.title,
                    disabled: true
                })

            })
        }
    }
}
</script>

<style scoped>

</style>
