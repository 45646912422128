<template>
    <v-container>
        <div class="d-md-flex mb-8 line-bottom pb-4">
            <h1 class="text-h1 mb-3 mb-md-0">News</h1>
            <v-breadcrumbs :items="breadcrumbs" class="ml-auto pa-0"></v-breadcrumbs>
        </div>

        <v-card
            flat
            class="news-card"
            :class="{ 'offwhite-card': index%2 === 0 }"
            :to="{name: 'NewsItem', params: {slug: item.slug}}" elevation="0"
            v-for="(item, index) in paginatedData" :key="item.id"
        >
            <v-row class="align-center flex-wrap">
                <v-col sm="12" md="4" lg="2" class="py-0">
                    <v-img
                        v-if="item.image"
                        :src="item.image.url"
                        aspect-ratio="1.5"
                        :alt="item.title"
                        class="d-sm-none d-md-flex"
                    ></v-img>
                    <v-img
                        v-if="item.image"
                        :src="item.image.url"
                        max-height="200px"
                        :alt="item.title"
                        class="d-none d-sm-flex d-md-none"
                    ></v-img>
                </v-col>
                <v-col sm="12" md="8" lg="10">
                    <v-card-subtitle class="pt-0 pb-0">
                        {{ item.date|luxon('dd.MM.yyyy') }}
                    </v-card-subtitle>
                    <v-card-title class="pt-2">
                        <router-link :to="{name: 'NewsItem', params: {slug: item.slug}}">{{
                                item.title
                            }}
                        </router-link>
                    </v-card-title>
                    <v-card-text class="pb-0">
                        {{ item.summary }} <span class="link ml-1">Read more <v-icon x-small>fal fa-angle-right</v-icon></span>
                    </v-card-text>
                </v-col>
            </v-row>
        </v-card>

        <v-pagination v-model="pagination.pageNumber" :length="pages" :total-visible="10" v-if="items.length > pagination.itemsPerPage"
                      @input="nextPage" class="mt-5 mb-5"></v-pagination>
    </v-container>
</template>

<script>
export default {
    name: "NewsOverview",
    mounted() {
        this.$store.dispatch('getNewsItems')
        this.$store.dispatch('setPageTitle', 'News')
    },
    data: () => ({
        pagination: {
            pageNumber: 1,
            itemsPerPage: 8
        },
        breadcrumbs: [
            {
                text: 'Home',
                disabled: false,
                to: { name: 'Home' },
                exact: true
            },
            {
                text: 'News',
                disabled: false,
                to: { name: 'NewsOverview' },
                exact: true
            }
        ]

    }),
    computed: {
        items() {
            return this.$store.state.news.newsItems;
        },
        pages() {
            return Math.ceil(this.items.length / this.pagination.itemsPerPage)
        },
        paginatedData() {
            const start = this.pagination.pageNumber * this.pagination.itemsPerPage - this.pagination.itemsPerPage, //sets the correct start on data
                end = start + this.pagination.itemsPerPage;
            return this.items.slice(start, end);
        }
    },
    methods: {
        nextPage(page) {
            this.pagination.pageNumber = page;
        }
    }
}
</script>

<style scoped>

</style>
